/**
 * Mapping: https://www.webtype.com/info/articles/fonts-weights/
 */
export const fontFamily = {
  book: '"Circular-Pro-Book-S","Roboto", sans-serif' /* Font weight: 300 */,
  medium: '"Circular-Pro-Medium-S","Roboto", sans-serif' /* Font weight: 500 */,
  bold: '"Circular-Pro-Bold-S","Roboto", sans-serif' /* Font weight: 700 */,
};

/**
 * @type {{hellzaLarge: 48px,
 *        hellaLarge: 36px,
 *        damnLarge: 32px,
 *        quiteExtraExtraLarge: 30px,
 *        extraExtraLarge: 28px,
 *        extraLarge: 24px,
 *        large: 22px,
 *        mediumLarge: 20px,
 *        medium: 18px,
 *        default: 16px,
 *        mediumSmall: 14px,
 *        small: 12px,
 *        extraSmall: 11px}} FontSize
 */
export const fontSize = {
  hellzaLarge: '48px',
  hellaLarge: '36px',
  damnLarge: '32px',
  quiteExtraExtraLarge: '30px',
  extraExtraLarge: '28px',
  quiteExtraLarge: '26px',
  extraLarge: '24px',
  large: '22px',
  mediumLarge: '20px',
  medium: '18px',
  default: '16px',
  mediumSmall: '14px',
  small: '12px',
  extraSmall: '11px',
};
