import Script from 'next/script';
import React, { useEffect, useState } from 'react';
import { INTERCOM_APP_ID, INTERCOM_ENABLED } from '../../config';
import { useInterval } from 'beautiful-react-hooks';
import { useSelector } from '../../types/Redux';
import { getFriendlyRoleName } from '../../core/util/clinicAccounts';
import { log } from '../../core/logger/log';
import { useQuery } from 'react-query';
import { HttpError, httpGetJson } from '../../core/http/http';

type IntercomUserPropeties = {
  email: string;
  phone: string;
  name: string;
  user_id: string;
  user_hash: string;
  [key: string]: string;
};

export const Intercom = {
  getInstance: () => {
    const w = window as any;
    return w.Intercom;
  },
  loaded: () => {
    return Intercom.getInstance() != null;
  },
  boot: (properties: IntercomUserPropeties) => {
    console.debug('booting up intercom', properties);
    Intercom.getInstance()('boot', {
      app_id: INTERCOM_APP_ID,
      ...properties,
    });
  },
  isBooted: () => {
    return Intercom.getInstance()?.booted === true;
  },
  update: (properties: IntercomUserPropeties) => {
    console.debug('updating intercom', properties);
    Intercom.getInstance()('update', {
      app_id: INTERCOM_APP_ID,
      ...properties,
    });
  },
  move: (direction: 'left' | 'right') => {
    Intercom.getInstance()?.('update', {
      alignment: direction,
    });
  },
};

function useIntercomHash({ clinicAccountId }: { clinicAccountId: string }) {
  const { data, refetch, ...other } = useQuery<{ hash: string }, HttpError>(
    ['intercom-hash', clinicAccountId],
    () => {
      if (!clinicAccountId) {
        return { hash: '' };
      }
      return httpGetJson<{ hash: string }>(`/api/intercom/hash?clinicAccountId=${clinicAccountId}`);
    },
    {
      // This hash will never change, so we don't need to auto refetch it
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      onError: (err) => {
        log.error(`Failed to load intercom support chat - ${err.formatted()}`);
      },
    }
  );

  return {
    hash: (data?.hash ?? '') as string,
    other,
  };
}

export function IntercomChat({ hide }: { hide: boolean }) {
  const account = useSelector((w) => w.login?.account?.id);

  if (hide || !account || !INTERCOM_ENABLED) {
    return null;
  }

  return <IntercomChatInternal />;
}

function IntercomChatInternal() {
  const [shouldLoadScript, setShouldLoadScript] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { email, username, first_name, last_name, id, phone, groups, roles } = useSelector(
    (w) => w.login?.account ?? {}
  );
  const { hash } = useIntercomHash({
    clinicAccountId: id,
  });

  const [, clear] = useInterval(() => {
    if (Intercom.loaded()) {
      setScriptLoaded(true);
      clear();
    }
  }, 500);

  useEffect(() => {
    if (!scriptLoaded || !hash) {
      return;
    }
    try {
      const properties = {
        email: username || email,
        name: `${first_name} ${last_name}`,
        user_hash: hash,
        user_id: id,
        phone: phone,
        groups: groups?.map((w) => w.name).join(',') ?? '',
        roles: roles?.map(getFriendlyRoleName).join(', ') ?? '',
        version: process.env.NEXT_PUBLIC_MANAGE_VERSION || 'unknown',
      };
      console.log(properties);
      if (!Intercom.isBooted()) {
        Intercom.boot(properties);
      } else {
        Intercom.update(properties);
      }
    } catch (ex) {
      log.error(ex);
    }
  }, [email, first_name, groups, id, last_name, phone, roles, scriptLoaded, username, hash]);

  useEffect(() => {
    if (email && !Intercom.loaded()) {
      setShouldLoadScript(true);
    }
  }, [email, shouldLoadScript]);

  if (shouldLoadScript) {
    return (
      <Script
        id={'intercom-chat'}
        dangerouslySetInnerHTML={{
          __html: `
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ggxrd885';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      `,
        }}
      />
    );
  }

  return null;
}
