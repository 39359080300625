import { useEffect } from 'react';
import { removeAuth } from '../../core/auth';
import { clearPersistedStore } from '../../store/configureStore';

export default function LogOut() {
  useEffect(() => {
    // Clears the persisted redux store and removes the login cookie
    clearPersistedStore();
    removeAuth();
    window.location.replace('/login');
  }, []);
  return <></>;
}
