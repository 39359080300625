import { DAPI_HOST, DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { apiPostDispatchable } from './index';

const getLoginUrl = () => `${DAPI_HOST}/v1/auth/token`;

const buildLoginPostData = (props) => ({
  client_id: DAPI_CLIENT_ID,
  client_secret: DAPI_CLIENT_SECRET,
  username: props.username,
  password: props.password,
  grant_type: 'clinic',
});

const postLoginDispatchable = (props, onSuccess, onFailure) => {
  const postData = buildLoginPostData(props);

  return (dispatch) => {
    dispatch(apiPostDispatchable(getLoginUrl(), postData, onSuccess, onFailure));
  };
};

export { getLoginUrl, postLoginDispatchable };
