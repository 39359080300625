import React, { PureComponent } from 'react';
import { Row, Menu, Dropdown, Icon, notification, Tag } from 'antd';
import { getTrackingProperties } from '../../core/analytics/properties';
import history from '../../core/history';
import { getQueueUrlPath, getTelemedOnlyReportsRedirect } from '../../core/util/url';
import { getAccountHolderName } from '../../core/util/clinicAccounts';
import { shouldShowFeature } from '../../core/viewCustomization';
import { isSolvEmployeeAccount } from '../../core/session';
import { analyticsTrackEvent } from '../../core/analytics';
import {
  TOP_NAV_SELECT_CALL_CENTER,
  TOP_NAV_SELECT_REPORTS,
  TOP_NAV_SELECT_USERS,
  TOP_NAV_SELECT_SETTINGS,
  TOP_NAV_SELECT_PAYMENTS,
  TOP_NAV_SELECT_LOG_OUT,
  TOP_NAV_SELECT_QUEUE,
  TOP_NAV_SELECT_RESPONSIVE_REPORTS_LIVE_STATS,
  TOP_NAV_SELECT_REVIEWS_MODERATION,
  TOP_NAV_SELECT_REVIEWS,
} from '../../core/analytics/events';
import { isTestLocation } from '../../core/util/location';
import { removeCookie } from '../../core/util/cookie';
import { LAST_REVIEW_SEEN_COOKIE_NAME } from '../../config';
import styled from 'styled-components';
import { breakpoints } from '../primitives/constants';
import withViewport from '../withViewport';
import { colors } from '../../constants/colors';
import { HELP_CENTER_URL } from '../../constants/url';
import { FeatureName } from '../../core/viewCustomization/featureNames';
import { AccountLogin } from '../../types/RootState';
import { RemixRoutes } from '../../routes/remixRoutes';
import { getBooleanFlag } from '../../core/flags/flags';

const StyledTopNav = styled.div`
  height: 72px;
  border-bottom: 1px solid #e9e9e9;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    padding: 0 20px 0 4px;
    margin: 0 -8px;
  }
`;

const MenuItem = Menu.Item;

const ResponsiveMenuItem = styled(MenuItem)`
  display: none;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: block;
  }
`;

const DesktopMenuItem = styled(MenuItem)`
  display: block;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    display: none !important;
  }
`;

const StyledMenu = styled(Menu)`
  display: flex;
  .ant-menu-item {
    padding: 10px !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    &:hover {
      color: ${colors.solvPink} !important;
    }
  }
  .ant-menu-item-selected {
    color: ${colors.solvPink} !important;
    &:after {
      background-color: ${colors.solvPink};
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    .ant-menu-item {
      font-size: 14px;
      padding: 8px 0 !important;
    }
  }
`;

const StyledAccountDropdown = styled.div`
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .anticon {
    font-size: 13px;
    color: ${colors.dorianGrey};
  }
`;

const ResponsiveDropDown = styled(Row)`
  .top-nav-responsive-dropdown {
    display: none;
  }
  .top-nav-desktop-dropdown {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
  }

  @media screen and (max-width: ${breakpoints.tablet}px) {
    .top-nav-desktop-dropdown {
      display: none;
    }

    .top-nav-responsive-dropdown {
      display: block;
      margin-top: 24px;
    }
  }
`;

interface TopNavProps {
  login: AccountLogin;
  selectedLocationIds: string[];
  route: string;
  showTitle: boolean;
  getWaitTime(locationId: string): void;
  clearDemoData(locationId: string): Promise<void>;
  resetDemoData(locationId: string): Promise<void>;
}

class TopNav extends PureComponent<TopNavProps> {
  demoActionsEnabled() {
    return (
      isSolvEmployeeAccount(this.props.login) &&
      this.props.selectedLocationIds.length === 1 &&
      this.props.selectedLocationIds.some((selectedId) => {
        const selectedLoc = this.props.login.account.locations.find(
          (accLoc) => accLoc.location_id === selectedId
        );
        return selectedLoc && isTestLocation(selectedLoc);
      })
    );
  }

  getAccountDropDownMenu = () => {
    const demoActionsAreEnabled = this.demoActionsEnabled();
    return (
      <Menu onClick={this.handleMenuClick}>
        {demoActionsAreEnabled && (
          <DesktopMenuItem key="clearDemoData">Clear Demo Data</DesktopMenuItem>
        )}
        {demoActionsAreEnabled && (
          <DesktopMenuItem key="resetDemoData">Reset Demo Data</DesktopMenuItem>
        )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.QUEUE_TAB) && (
            <ResponsiveMenuItem key="queue">Queue</ResponsiveMenuItem>
          )}
        {this.props.route !== 'hello' &&
          shouldShowFeature(this.props.login, FeatureName.REPORTS_LIVE_DASHBOARD_TAB) && (
            <ResponsiveMenuItem key="reports">Reports</ResponsiveMenuItem>
          )}
        <MenuItem key="logout">Log out</MenuItem>
      </Menu>
    );
  };

  getPopupContainerForAccountDropDown = () =>
    document.getElementById('top-nav-account-drop-down-container');

  trackTopNavClick = (menuItem: string) => {
    let trackingEvent;

    switch (menuItem) {
      case 'call-center':
        trackingEvent = TOP_NAV_SELECT_CALL_CENTER;
        break;
      case 'reports':
        trackingEvent = TOP_NAV_SELECT_REPORTS;
        break;
      case 'users':
        trackingEvent = TOP_NAV_SELECT_USERS;
        break;
      case 'settings':
        trackingEvent = TOP_NAV_SELECT_SETTINGS;
        break;
      case 'payments':
        trackingEvent = TOP_NAV_SELECT_PAYMENTS;
        break;
      case 'reviews-moderation':
        trackingEvent = TOP_NAV_SELECT_REVIEWS_MODERATION;
        break;
      case 'logout':
        trackingEvent = TOP_NAV_SELECT_LOG_OUT;
        break;
      case 'queue':
        trackingEvent = TOP_NAV_SELECT_QUEUE;
        break;
      case 'live-stats':
        trackingEvent = TOP_NAV_SELECT_RESPONSIVE_REPORTS_LIVE_STATS;
        break;
      case 'reviews':
        trackingEvent = TOP_NAV_SELECT_REVIEWS;
        break;
      default:
        break;
    }

    if (trackingEvent) {
      analyticsTrackEvent(trackingEvent, getTrackingProperties({ login: this.props.login }));
    }
  };

  handleMenuClick = ({ key }: { key: string }) => {
    this.trackTopNavClick(key);
    switch (key) {
      case 'queue': {
        history.push(getQueueUrlPath([]));
        break;
      }
      case 'reviews': {
        window.location.replace(RemixRoutes.reviews);
        break;
      }
      case 'connect':
      case 'call-center':
      case 'hello':
      case 'patients':
      case 'payments':
      case 'reviews-moderation':
      case 'platform-usage-alerts':
        history.push(`/${key}`);
        break;
      case 'reports':
        history.push(getTelemedOnlyReportsRedirect(this.props.login, key));
        break;
      case 'live-stats':
        history.push(`/reports/${key}`);
        break;
      case 'settings':
        history.push(`/${key}`);
        break;
      case 'logout':
        history.push('/log-out');
        break;
      case 'clearDemoData':
        notification.info({
          duration: 0,
          message: (
            <div>
              Clearing demo data, hold plz....
              <br />
              This page will refresh automatically.
            </div>
          ),
        });

        if (this.props.selectedLocationIds[0]) {
          this.props.clearDemoData(this.props.selectedLocationIds[0]);
        }

        break;
      case 'resetDemoData':
        removeCookie(LAST_REVIEW_SEEN_COOKIE_NAME);
        notification.info({
          duration: 0,
          message: (
            <div>
              Resetting demo data, hold plz....
              <br />
              This page will refresh automatically.
            </div>
          ),
        });
        if (this.props.selectedLocationIds[0]) {
          this.props.resetDemoData(this.props.selectedLocationIds[0]);
        }

        break;
      default:
        break;
    }
  };

  _showReputationManagement = getBooleanFlag('show-reputation-management');

  render = () => (
    <Row>
      <StyledTopNav
        // @ts-ignore
        align="space-between"
        className="top-nav disable-scrollbars"
      >
        <StyledMenu
          mode="horizontal"
          onClick={this.handleMenuClick}
          selectedKeys={[this.props.route]}
        >
          <MenuItem className="top-nav-location-logo-container" key="hello">
            <div className="top-nav-logo-container">
              <img
                alt="location logo"
                className="top-nav-location-logo"
                src="/images/logo/dark_with_pink_dot.png"
              />
            </div>
          </MenuItem>
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.QUEUE_TAB) && (
              <DesktopMenuItem key="queue">Queue</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.CONNECT_TAB) && (
              <DesktopMenuItem key="connect">Connect</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.CALL_CENTER) && (
              <DesktopMenuItem key="call-center">Call Center</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.REPORTS_TAB) && (
              <DesktopMenuItem key="reports">Reports</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.HELP_CENTER) && (
              <DesktopMenuItem key="help-center">
                <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
                  Help Center
                </a>
              </DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.SETTINGS_TAB) && (
              <DesktopMenuItem key="settings">Settings</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.PATIENTS_TAB) && (
              <DesktopMenuItem key="patients">Patients</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.PAYMENTS_TAB) && (
              <DesktopMenuItem key="payments">Payments</DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.REVIEWS_MODERATION) && (
              <DesktopMenuItem key="reviews-moderation">Reviews Moderation</DesktopMenuItem>
            )}
          {this._showReputationManagement &&
            this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.REPORTS_REVIEWS_TAB) && (
              <DesktopMenuItem key="reviews">
                <div className="flex items-center gap-1 max-w-[105px]">
                  Reviews
                  <div>
                    <Tag style={{ fontSize: '12px' }} color="magenta">
                      New
                    </Tag>
                  </div>
                </div>
              </DesktopMenuItem>
            )}
          {this.props.route !== 'hello' &&
            shouldShowFeature(this.props.login, FeatureName.PLATFORM_USAGE_ALERTS) && (
              <DesktopMenuItem key="platform-usage-alerts">Usage Alerts</DesktopMenuItem>
            )}
        </StyledMenu>
        <div id="top-nav-account-drop-down-container" />
        <Dropdown
          // @ts-ignore
          getPopupContainer={this.getPopupContainerForAccountDropDown}
          overlay={this.getAccountDropDownMenu()}
          placement="bottomRight"
          trigger={['click', 'hover']}
        >
          <ResponsiveDropDown className="ant-menu ant-menu-horizontal">
            <StyledAccountDropdown className="top-nav-desktop-dropdown">
              {getAccountHolderName(this.props.login.account)}
              <Icon type="down" />
            </StyledAccountDropdown>
            <img
              src={'/images/queue/Vectorhamburger.png'}
              className="top-nav-responsive-dropdown"
              alt="menu"
            />
          </ResponsiveDropDown>
        </Dropdown>
      </StyledTopNav>
    </Row>
  );
}

export default withViewport(TopNav);
